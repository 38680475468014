
import OrderOverviewComponent from "./components/OrderOverviewComponent";

export class KuennePlugin  {constructor() { KuennePlugin.prototype.__init.call(this); }
  __init() {this.name = "KuennePlugin"}

  async onFactory(factory) {
    await factory.registerTranslationResolver(
      "en",
      "kuenne",
      async () => await import("./translations/en.json")
    );
    await factory.registerTranslationResolver(
      "de",
      "kuenne",
      async () => await import("./translations/de.json")
    );

    factory.registerRoute({
      path: "/orders/*",
      props: {},
      component: async () => ({ default: OrderOverviewComponent }),
    });
  }
}
